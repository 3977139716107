import { LorryNumRegExp } from "@vahak/core/dist/constants/regex";

import * as Yup from "yup";
import { lorryCreationFormFieldNames, LORRY_CREATION_FORM_IDS } from "../constants";
import { lorryValidationSchema } from "./common";

export const lorryNumValidation = {
    [lorryCreationFormFieldNames.lorryNum]: Yup.string()
        .matches(LorryNumRegExp, "Please enter a valid Lorry Number")
        .min(7, "Please enter a valid Lorry Number")
        .max(11, "Please enter a valid Lorry Number")
        .required("Lorry number is required!")
};

export const lorryFormValidations = {
    [LORRY_CREATION_FORM_IDS.VEHICLE_NUMBER]: Yup.object().shape({
        ...lorryNumValidation
    }),
    [LORRY_CREATION_FORM_IDS.VEHICLE_TYPE]: Yup.object().shape({
        ...lorryValidationSchema
    }),
    [LORRY_CREATION_FORM_IDS.VEHICLE_ROUTES_SELECTION]: Yup.object().shape({
        ...lorryValidationSchema,
        [lorryCreationFormFieldNames.currentCityText]: Yup.string().required("Current location is required!"),
        [lorryCreationFormFieldNames.priceDestinations!]: Yup.array()
            .min(1, "Select one route atleast")
            ?.required("Select one route atleast")
    })
};
